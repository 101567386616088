interface SonstigeMaschinenDetailsProps {
  maschine: any
}

export const SonstigeMaschinenDetails = ({ maschine, ...props }: SonstigeMaschinenDetailsProps) => {
  let machine_model = 'unbekannt'
  let sps_model = 'unbekannt'
  if (maschine.device_type) {
    if ('gpio' === maschine.device_type) {
      machine_model = 'ALB ready'
    }
    if ('modbus' === maschine.device_type) {
      machine_model = 'ALB'
    }
    if ('s7' === maschine.device_type) {
      machine_model = 'ALB'
    }

    if ('gpio' === maschine.device_type) {
      sps_model = 'ALB ready'
    }
    if ('modbus' === maschine.device_type) {
      sps_model = 'Samos'
    }
    if ('s7' === maschine.device_type) {
      sps_model = 'Logo'
    }
  }

  return (
    <div>
      <div><strong>Sonstige Maschinendaten</strong></div>
      <div>
        Maschinentyp: {machine_model}
      </div>
      <div>
        Anzahl Sammelwagen: {maschine.anzahlSammelwagen ?? <i>Daten nicht gepflegt</i>}
      </div>
      <div>
        Inbetriebnahme Maschine: {maschine.inbetriebnahmeMaschine ?? <i>Daten nicht gepflegt</i>}<br />
        Inbetriebnahme Modem: {maschine.inbetriebnahmeModem ?? <i>Daten nicht gepflegt</i>}
      </div>
    </div>
  )
}
