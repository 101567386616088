export default function RulesDetails({ maschine, ...props }) {
  const rulesFiltered = !maschine.rules
    ? []
    : maschine.rules.filter((rule) => {
      if (rule.events === 'Stoerung') {
        return false
      }
      if (Array.isArray(rule.events) && rule.events.length === 1 && rule.events[0] === 'Stoerung') {
        return false
      }
      return true
    })
  return (
    <div>
      <strong>Benachrichtigungs-Regeln</strong><br />
      {rulesFiltered && rulesFiltered.length > 0
        ? <ul style={{
          paddingLeft: 0,
          marginTop: 0,
          marginBottom: 0
        }}>
          {rulesFiltered.map((rule) => (
            <RuleDetails key={rule.name} rule={rule} />
          ))}
        </ul>
        : 'keine'
      }
    </div>
  )
}

function Tag({ name, ...props }) {
  return (
    <li style={{
      color: '#444',
      display: 'inline',
      padding: '0 3px',
      marginLeft: '2px',
      borderRadius: '8px',
      border: '2px solid lightgray',
      backgroundColor: 'whitesmoke'
    }}>
      {name}
    </li>
  )
}

function TagList({ tags, ...props }) {
  return (
    <ul style={{
      display: 'inline',
      paddingLeft: 0
    }}>
      {Array.isArray(tags)
        ? tags.map((tag) => (
          <Tag key={tag} name={tag} />
        ))
        : <Tag key={tags} name={tags} />
      }
    </ul>
  )
}

function RuleDetails({ rule, ...props }) {
  return (
    <li style={{
      display: 'block',
      marginTop: '0.3em'
    }}>
      <div>{rule.name}</div>
      Bei <TagList tags={rule.events} />: {rule.type} an {rule.to}
    </li>
  )
  // TODO: Hinzufügen: Mail / SMS Icon
  // TODO: Hinzufügen: "Zuletzt ausgeführt: ..."
}
