export default function StandortDetails({ maschine, ...props }) {
  return (
    <div>
      <strong>Standort</strong><br />
      {maschine.adresse
        ? <div>
          {maschine.adresse.bezeichnung}<br />
          {maschine.adresse.strasse} {maschine.adresse.hausnummer}<br />
          {maschine.adresse.plz} {maschine.adresse.ort}<br />
        </div>
        : 'unbekannt'}
    </div>
  )
}
