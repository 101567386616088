import * as React from 'react';
import { useParams } from 'react-router-dom';
import './maschine.css'

import { Amplify, API, Auth } from 'aws-amplify';
import config from '../config'
import MaschinenDetails from '../components/maschinen-details/maschinen-details'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'maschinenApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});

const authPath = '/maschine'

async function getMaschine(seriennummer) {
  // console.log('getMaschine')
  const maschine = await fetchData(seriennummer)
  // console.log(`sn: ..${seriennummer}..`)
  // console.log(maschine)
  return maschine
}

async function fetchData(seriennummer) {
  const apiName = 'maschinenApi'
  const path = `${authPath}/${seriennummer}`
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };
  return await API.get(apiName, path, myInit)
}

export default function Maschine() {
  let params = useParams();
  const [maschine, setMaschine] = React.useState({})

  React.useEffect(() => {
    getMaschine(params.seriennummer)
      .then(m => {
        // console.log(m)
        setMaschine(m)
      })
      .catch(err => {
        console.log('err:')
        console.log(err)
        setMaschine({})
      })
  }, [params])

  return (
    <>
      {maschine
        ? <MaschinenDetails maschine={maschine} />
        : 'Keine Maschinendaten geladen, bitte warten...'}
    </>
  );
}
