import * as React from 'react';
import { useParams } from 'react-router-dom';
// import './reports.css'

import { Amplify, API, Auth } from 'aws-amplify';
import config from '../config'
import { Breadcrumbs, getLinkToMaschineDailyReport } from '../LinkUtils'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'maschinenApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});

export function DailyReportsList({ seriennummer, ...props }) {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async (seriennummer) => {
      try {
        const apiName = 'maschinenApi'
        const path = `/maschine/${seriennummer}/reports/daily`
        const myInit = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
          }
        }
        const reports = await API.get(apiName, path, myInit)
        if (!reports || !reports.latest) {
          throw new Error(`Keine Daten für ${seriennummer} gefunden.`)
        }
        setData(reports)
        setError(null)
      } catch (err) {
        console.error('Error:', err)
        setData(null)
        setError(err.message)
      } finally {
        setLoading(false)
      }

    }
    fetchData(seriennummer)
  }, [seriennummer])

  if (loading) {
    return <div>Lade Daten...</div>
  }
  if (error) {
    return <div>{`Fehler beim Laden der Daten - ${error}`}</div>
  }
  const maschineDummy = {
    SerienNummer: seriennummer
  }
  const reportsMapped = data.latest.map(dateOfReport => {
    const dateHuman = new Date(dateOfReport).toDateString()
    const link = getLinkToMaschineDailyReport(maschineDummy, dateOfReport)
    return <li key={dateOfReport}><a href={link}>{dateHuman}</a></li>
  })
  return <ul>{reportsMapped}</ul>
}

export default function DailyReports() {
  let params = useParams();
  return (
    <div style={{ display: 'flex' }}>
      <main style={{ padding: '0rem 1rem' }}>
        <h2>Daily Reports für {params.seriennummer} (&lt;- das ist die interne S/N)</h2>
        <Breadcrumbs seriennummer={params.seriennummer} level1='reports' />

        <p>Hier finden Sie die letzten Berichte für die Maschine {params.seriennummer}. Durch Anklicken wird der Bericht geöffnet.</p>

        <DailyReportsList seriennummer={params.seriennummer} />
      </main>
    </div>
  );
}
