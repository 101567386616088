import { useSearchParams } from 'react-router-dom';
import MaschinenLink from './MaschinenLink'

export default function MaschinenListe({ maschinen, ...props }) {
  let [searchParams, setSearchParams] = useSearchParams({ replace: true })

  // maschinen can be `undefined` (during loading), `[]` (if no devices were returned by the service), or a valid list
  const maschinenFilteredAndMapped = maschinen
    ? maschinen
      .filter((maschine) => {
        const filter = searchParams.get('filter')
        if (!filter) return true
        const haystack = [maschine.ext_sn, maschine.adresse.strasse, maschine.adresse.ort, maschine.adresse.bezeichnung].join('#').toLowerCase()
        return haystack.includes(filter.toLowerCase())
      })
      .map((maschine) => (
        <MaschinenLink key={maschine.SerienNummer} maschine={maschine} />
      ))
    : maschinen

  return (
    <>
      {maschinenFilteredAndMapped && maschinenFilteredAndMapped.length > 0
        ? maschinenFilteredAndMapped
        : <div className='psi-search-result-info'>
          {!maschinen
            ? 'Lade Daten...'
            : maschinen.length === 0
              ? 'Keine Daten.'
              : 'Keine Treffer.'}
        </div>}
      <br />
      <br />
    </>
  )
}
