import { getLinkToMaschineStoerungen } from '../../LinkUtils'

export default function Fehlerliste({ maschine, maxDisplayLength = 10, ...props }) {
  // case of ALB ready
  if (maschine.ext_sn && maschine.ext_sn.startsWith('411.')) {
    return (
      <div>
        <strong>Störungen</strong><br />
        <div>Für ALB ready Maschinen können keine Störungen angezeigt werden.</div>
      </div>
    )
  }

  // case of empty error list
  const errorList = maschine.errors
  if (!errorList || errorList.length === 0) {
    return (
      <div>
        <strong>Störungsfrei</strong><br />
        <div>Für diese Maschine liegen keine Störungen vor.</div>
      </div>
    )
  }

  const errorListSorted = errorList
    .sort((e1, e2) => {
      // neg. if first is less
      // 0 if equal
      // pos. otherwise
      return e1.dt < e2.dt ? 1 : -1
    })
  const errorListClipped = maxDisplayLength
    ? errorListSorted.slice(0, maxDisplayLength)
    : errorListSorted
  const errorListMapped = errorListClipped.map((errorEvent) => (
    <Fehlereintrag key={errorEvent.dt} fehler={errorEvent} />
  ))
  const isClipped = errorList.length > errorListMapped.length

  return (
    <div>
      <strong>Störungen</strong> ({errorList.length > errorListMapped.length
        ? <>
          letzte {maxDisplayLength} Einträge &ndash; <a href={getLinkToMaschineStoerungen(maschine)}>alle zeigen</a>
        </>
        : <>{errorList.length} Einträge</>})<br />
      <table className="stoerungen">
        <thead>
          <tr>
            <th>Datum</th>
            <th></th>
            <th>Uhrzeit</th>
            <th></th>
            <th>Störung</th>
          </tr>
        </thead>
        <tbody>
          {errorListMapped}
        </tbody>
      </table>
    </div>
  )
}

function Fehlereintrag({ fehler, ...props }) {
  const dateOfError = new Date(fehler.dt)
  const [date, time] = dateOfError.toISOString().split('.')[0].split('T')
  const ageInSeconds = Math.floor((new Date() - dateOfError) / 1000)
  const ageInDays = ageInSeconds / 86400
  const rowClassName = ageInDays < 14 ? 'stoerung-recent' : 'stoerung-old'
  return (
    <tr className={rowClassName}>
      <td>{date}</td>
      <td>&nbsp;</td>
      <td>{time}</td>
      <td>&nbsp;</td>
      <td>{fehler.eventName}</td>
    </tr>
  )
}
