interface KommentareProps {
  maschine: any
}

export const Kommentare = ({ maschine, ...props }: KommentareProps) => {
  return (
    <div>
      {maschine.comments
        ? <>
          <div><strong>Kommentare</strong></div>
          <div>{maschine.comments.map((comment: string) => <div>{comment}</div>)}</div>
        </>
        : <div>Keine Kommentare</div>
      }
    </div>
  )
}
