export const getLinkToMaschine = (maschine) => {
    // This function has maschine by design.
    // This way, we can switch links from internal to external serial numbers in the future.
    return `/maschinen/${maschine.SerienNummer}/`
}
export const getLinkToMaschineStoerungen = (maschine) => `${getLinkToMaschine(maschine)}stoerungen/`
export const getLinkToMaschineReportsOverview = (maschine) => `${getLinkToMaschine(maschine)}reports/`
export const getLinkToMaschineDailyReportsOverview = (maschine) => `${getLinkToMaschine(maschine)}reports/daily/`
export const getLinkToMaschineDailyReport = (maschine, dateOfReport) => `${getLinkToMaschineDailyReportsOverview(maschine)}${dateOfReport}/`
export const getLinkToMaschineBaleStatsOverview = (maschine) => `${getLinkToMaschine(maschine)}reports/bales/`
export const getLinkToMaschineBaleStats = (maschine, baleStatsId) => `${getLinkToMaschineBaleStatsOverview(maschine)}${baleStatsId}/`

export const Breadcrumbs = ({ seriennummer, level1, level2, ...props }) => {
    const maschineDummy = {
        SerienNummer: seriennummer
    }
    return (
        <p className='breadcrumbs'>
            Zurück zur{' '}
            <a href={getLinkToMaschine(maschineDummy)}>Maschine</a>
            {'reports' === level1
                ? <>
                    {' > '}
                    <a href={getLinkToMaschineReportsOverview(maschineDummy)}>Berichte-Übersicht</a>
                </>
                : ''
            }
            {'daily-reports' === level2
                ? <>
                    {' > '}
                    <a href={getLinkToMaschineDailyReportsOverview(maschineDummy)}>Übersicht Daily Reports</a>
                </>
                : ''
            }
            {'bale-stats' === level2
                ? <>
                    {' > '}
                    <a href={getLinkToMaschineBaleStatsOverview(maschineDummy)}>Übersicht Ballenberichte</a>
                </>
                : ''
            }
        </p>
    )
}