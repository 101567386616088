import { useSearchParams } from 'react-router-dom';

export default function SearchField() {
  let [searchParams, setSearchParams] = useSearchParams({ replace: true });
  return (
    <input
      placeholder='Suche...'
      value={searchParams.get('filter') || ''}
      onChange={(event) => {
        let filter = event.target.value;
        if (filter) {
          setSearchParams({ filter }, { replace: true });
        } else {
          setSearchParams({}, { replace: true });
        }
      }}
    />
  )
}
