import { Flex, Image, useTheme } from "@aws-amplify/ui-react"

export default function AuthenticatorHeader() {
  const { tokens } = useTheme()
  return (
    <Flex justifyContent="center" alignItems="flex-end">
      <Image
        alt="Strautmann Umwelttechnik"
        src="/logo.png"
        padding={tokens.space.medium}
        />
      <div style={{
          fontSize: '47px',
          color: 'var(--su-blue)',
          fontWeight: 'bold'
          }}>
        PSI
      </div>
    </Flex>
  )
}