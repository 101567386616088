import { useEffect, useState } from 'react'
import * as React from 'react';
import { useParams } from 'react-router-dom';
// import './reports.css'

import { Amplify, API, Auth } from 'aws-amplify';
import config from '../config'
import { Breadcrumbs } from '../LinkUtils'
import ConnectivityIndicator from '../components/ConnectivityIndicator';
import { ReportHeader } from '../components/ReportHeader';

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'maschinenApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});

/** Converts a duration in seconds into a human-readable (German) string */
function secondsToString(seconds: number) {
  const numyears = Math.floor(seconds / 31536000)
  const numdays = Math.floor((seconds % 31536000) / 86400)
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numseconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
  const yearsStr = numyears ? numyears + " Jahre" : ''
  const daysStr = numdays ? numdays + " Tage" : ''
  const hoursStr = numhours < 10 ? '0' + numhours : numhours
  const minsStr = numminutes < 10 ? '0' + numminutes : numminutes
  const secsStr = numseconds < 10 ? '0' + numseconds : numseconds
  let timeStr
  if (numyears > 0
    || numdays > 0
    || numhours > 0) {
    timeStr = `${hoursStr}:${minsStr}:${secsStr} Stunden`
  } else {
    if (numminutes > 0) {
      timeStr = `${minsStr}:${secsStr} Minuten`
    } else {
      if (1 === numseconds) {
        timeStr = `${numseconds} Sekunde`
      } else {
        timeStr = `${numseconds} Sekunden`
      }
    }
  }
  return [yearsStr, daysStr, timeStr].join(' ')
}

const toLocalStringDe = (datestr_utc: string) => {
  return new Date(datestr_utc).toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

interface BaleStatsTableProps {
  data: any
}
function BaleStatsTable({ data, ...props }: BaleStatsTableProps) {
  const beginn: Date = new Date(data.start)
  const ende: Date = new Date(data.SK.split('#')[1])
  const dauer = secondsToString((+ende - +beginn) / 1000)
  const ejection_type = data.ejection_type.startsWith('before_bf')
    ? 'before_bf'
    : data.ejection_type

  return <>
    <h3>Ballen # {data.ballennummer}</h3>
    <table>
      <tbody>
        <tr><td>Beginn:</td><td>{toLocalStringDe(beginn.toISOString())}</td></tr>
        <tr><td>Ende:</td><td>{toLocalStringDe(ende.toISOString())}</td></tr>
        <tr><td>Dauer:</td><td>{dauer}</td></tr>
      </tbody>
    </table>
    {ejection_type === 'before_bf'
      ? <>
        <div><span style={{ fontSize: '11px' }}><ConnectivityIndicator isConnected={false} name={'Vorzeitiger Auswurf'} isDisabled={false} /></span> Vorzeitiger Auswurf</div>
      </>
      : ''}
    <table>
      <tbody>
        <tr><td>Anz. Handbef.:</td><td>{data.anzahl_handbefuellungen}</td></tr>
        <tr><td>Anz. Wagenbef.:</td><td>{data.anzahl_wagenbefuellungen}</td></tr>
        <tr><td>Anz. Presszyklen:</td><td>{data.anzahl_presszyklen}</td></tr>
      </tbody>
    </table>
    <table>
      <tbody>
        <tr><td>Entleerungszeit (Wagen):</td><td>{secondsToString(data.entleerungszeit_wagen)}</td></tr>
        <tr><td>Presszykluszeit (Wagen):</td><td>{secondsToString(data.presszykluszeit_wagen)}</td></tr>
        <tr><td>Presszykluszeit (gesamt):</td><td>{secondsToString(data.presszykluszeit)}</td></tr>
        {ejection_type === 'before_bf'
          ? ''
          : <tr><td>Dauer bis zum Auswurf:</td><td>{0 === data.dauer_bis_auswurf
            ? "konnte nicht ermittelt werden"
            : secondsToString(data.dauer_bis_auswurf)}</td></tr>}
        <tr><td>Abbinde-Dauer:</td><td>{0 === data.abbinde_dauer
          ? "konnte nicht ermittelt werden"
          : secondsToString(data.abbinde_dauer)}</td></tr>
        <tr><td>Reinigungs-Dauer:</td><td>{0 === data.reinigungs_dauer
          ? "konnte nicht ermittelt werden"
          : secondsToString(data.reinigungs_dauer)}</td></tr>
      </tbody>
    </table>
  </>
}

export default function BaleStats() {
  let params = useParams()

  const [maschineData, setMaschineData] = useState(null)
  const [reportData, setReportData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const fetchMaschineData = async (seriennummer: string) => {
      const apiName = 'maschinenApi'
      const path = `/maschine/${seriennummer}`
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }
      const maschine = await API.get(apiName, path, myInit)
      if (!maschine) {
        throw new Error(`Keine Maschinendaten für ${seriennummer} gefunden.`)
      }
      return maschine
    }
    const fetchReportData = async (seriennummer: string, baleStatsId: string) => {
      const apiName = 'maschinenApi'
      const path = `/maschine/${seriennummer}/reports/bales/${baleStatsId}`
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }
      const baleStats = await API.get(apiName, path, myInit)
      if (!baleStats || !baleStats.ballennummer) {
        throw new Error(`Keine Daten für ${seriennummer} und ${baleStatsId} gefunden.`)
      }
      console.log('baleStats', baleStats)
      return baleStats
    }
    const fetchAllData = async (params: any) => {
      try {
        const [myMaschineData, myReportData] = await Promise.all([
          fetchMaschineData(params.seriennummer!),
          fetchReportData(params.seriennummer!, params.baleStatsId!)
        ])
        setMaschineData(myMaschineData)
        setReportData(myReportData)
      } catch (err) {
        console.error('Error:', err)
        setMaschineData(null)
        setReportData(null)
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError((err as any).toString())
        }
      } finally {
        setLoading(false)
      }
    }
    fetchAllData(params)
  }, [params])

  const data = reportData
  let content
  if (loading) {
    content = <>
      <br />
      Lade Daten...
    </>
  } else if (error) {
    content = <>
      <br />
      <div>{`Fehler beim Laden der Daten - ${error}`}</div>
    </>
  } else {
    content = <>
      <ReportHeader maschine={maschineData} />
      <br />
      <BaleStatsTable data={reportData} />
    </>
  }

  return (
    <div style={{ display: 'flex' }}>
      <main style={{ padding: '0rem 1rem' }}>
        <h2>Ballenbericht für {params.seriennummer} am {params.baleStatsId}</h2>
        <Breadcrumbs seriennummer={params.seriennummer} level1={'reports'} level2={'bale-stats'} />
        {content}
      </main>
    </div>
  )
}
