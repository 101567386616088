import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import App from './App'
import Maschinen from './routes/maschinen'
import Maschine from './routes/maschine'
import AlleStoerungen from './routes/stoerungen'
import DailyReport from './routes/daily-report'
import DailyReports from './routes/daily-reports'
import Reports from './routes/reports'
import BaleStats from './routes/bale-report'
import BaleReports from './routes/bale-reports'
import * as serviceWorker from './serviceWorker'

import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import aws_exports from './aws-exports'
Amplify.configure(aws_exports)

const root = createRoot(document.getElementById('root')!)
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          index
          element={
            <>
              <Authenticator>
                {({ signOut, user }) => (
                  <h2 style={{ marginLeft: '30px' }}>Hallo, {user!.attributes!.email}</h2>
                )}
              </Authenticator>
              <Maschinen viewMode="page" />
            </>
          }
        />

        <Route path="maschinen" element={<Maschinen viewMode="sidebar" />}>
          <Route
            index
            element={
              <>
                <h2>Maschinen-Übersicht</h2>
                <p>Wählen Sie eine Maschine aus um weitere Informationen zu erhalten.</p>
              </>
            }
          />
          <Route path=":seriennummer">
            <Route index element={<Maschine />} />

            <Route path="stoerungen">
              <Route index element={<AlleStoerungen />} />
            </Route>

            <Route path="reports">
              <Route index element={<Reports />} />

              <Route path="daily">
                <Route index element={<DailyReports />} />
                <Route path=":date" element={<DailyReport />} />
              </Route>

              <Route path="bales">
                <Route index element={<BaleReports />} />
                <Route path=":baleStatsId" element={<BaleStats />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          path="*"
          element={
            <main style={{ padding: '1rem' }}>
              <p>Hier gibt's nichts zu sehen!</p>
            </main>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
