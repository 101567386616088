import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './reports.css'

import { Amplify, API, Auth } from 'aws-amplify'
import { Flex } from '@aws-amplify/ui-react'

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, TimeScale, Title, Tooltip, Legend } from "chart.js"
import 'chartjs-adapter-luxon'
import { Bar } from "react-chartjs-2"

import config from '../config'
import { ReportHeader } from '../components/ReportHeader'
import { Breadcrumbs, getLinkToMaschineDailyReport } from '../LinkUtils'
import { DailyReportResponse, DailyReportRow } from '../typing/DailyReportResponse'
import { roundHalfHourDown, roundHalfHourUp } from '../DateTimeUtils'
import { ErrorBoundary } from 'react-error-boundary'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'maschinenApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
})
ChartJS.register(
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
)

const utcDateColumn = 'Datum'
const localDateColumn = 'Datum (Local)'
const utcTimeColumn = 'Uhrzeit (UTC)'
const localTimeColumn = 'Uhrzeit (Local)'

interface DailyReportChartJsProps {
  data: DailyReportResponse,
  loading: boolean,
  error: string | null
}
function DailyReportChartJs({ data, loading, error, ...props }: DailyReportChartJsProps) {
  if (loading) {
    return <div>Lade Daten...</div>
  }
  if (error) {
    return <div>{`Fehler beim Laden der Daten - ${error}`}</div>
  }

  const rows = data.rows

  const pzColumn = 'Zykluszeit'
  const ezColumn = 'Entleerungszeit'
  const bfColumn = 'Entleerungszeit'

  const xLabels = rows.map((row) => {
    const dtUtc = row[utcDateColumn] + 'T' + row[utcTimeColumn] + 'Z'
    return dtUtc
    // const tm = row[localTimeColumn]
    // return tm
  })
  // xLabels.unshift("x")
  console.log('xLabels', xLabels)

  const isHandRow = (row: DailyReportRow) => {
    return Number(row[ezColumn]) === 0
  }
  const isBfRow = (row: DailyReportRow) => {
    return row['Fehlermeldung'] === 'DBG:Ballen Fertig'
      || row['Fehlermeldung'] === 'Ballen fertig'
      || row['Fehlermeldung'] === 'Ballen ausgeworfen'
  }
  const isRestartRow = (row: DailyReportRow) => {
    return row['Fehlermeldung'].includes('DBG:Restart detected')
  }

  const pzSeries = rows.map((row) => {
    if (isBfRow(row) || isRestartRow(row)
      || isHandRow(row)) {
      return 0
    }
    return Number(row[pzColumn])
  })
  // pzSeries.unshift(pzColumn)
  console.log('pzSeries', pzSeries)

  const ezSeries = rows.map(row => {
    if (isBfRow(row) || isRestartRow(row)
      || isHandRow(row)) {
      return 0
    }
    return Number(row[ezColumn])
  })
  // ezSeries.unshift(ezColumn)
  console.log('ezSeries', ezSeries)

  const handSeries = rows.map(row => {
    if (isBfRow(row) || isRestartRow(row)
      || !isHandRow(row)) {
      return 0
    }
    return Number(row[pzColumn])
  })
  console.log('handSeries', handSeries)

  const bfSeries = rows.map(row => {
    if (!isBfRow(row)) {
      return 0
    }
    return 200
  })
  console.log('bfSeries', bfSeries)

  // const BORDER_COLORS = [
  //   'rgb(54, 162, 235)', // blue
  //   'rgb(255, 99, 132)', // red
  //   'rgb(255, 159, 64)', // orange
  //   'rgb(255, 205, 86)', // yellow
  //   'rgb(75, 192, 192)', // green
  //   'rgb(153, 102, 255)', // purple
  //   'rgb(201, 203, 207)' // grey
  // ];

  // // Border colors with 50% transparency
  // const BACKGROUND_COLORS = /* #__PURE__ */ BORDER_COLORS.map(color => color.replace('rgb(', 'rgba(').replace(')', ', 0.5)'));

  const barThickness = 6
  const barPercentage = 8
  const cdata = {
    labels: xLabels,
    datasets: [
      {
        label: "Entleerungszeit Wagenbefüllungen",
        data: ezSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: 'rgb(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)'
      },
      {
        label: "Presszeit Wagenbefüllungen",
        data: pzSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: 'rgb(255, 159, 64)', // orange
        backgroundColor: 'rgba(255, 159, 64, 0.5)', // orange
      },
      {
        label: "Presszeit Handbefüllungen",
        data: handSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: 'rgb(255, 99, 132)', // red
        backgroundColor: 'rgba(255, 99, 132, 0.5)', // red
        order: -2
      },
      {
        label: "Ballen Fertig",
        data: bfSeries,
        borderWidth: 1,
        barThickness: barThickness,
        // barPercentage: barPercentage,
        borderColor: '#63FF84',
        backgroundColor: '#B1FFC1',
        order: -1
      },
    ]
  }

  /** find the first and last entries which have a non-zero bar */
  let firstBar = -1
  let lastBar = 0
  for (let index = 0; index < xLabels.length; index++) {
    if (ezSeries[index] > 0
      || pzSeries[index] > 0
      || handSeries[index] > 0
      || bfSeries[index] > 0) {
      if (-1 === firstBar) {
        firstBar = index
      }
      lastBar = index
    }
  }
  const minDate = new Date(xLabels[firstBar])
  const suggestedMin = roundHalfHourDown(minDate).toISOString()

  const maxDate = new Date(xLabels[lastBar])
  const suggestedMax = roundHalfHourUp(maxDate).toISOString()

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `Pressvorgänge am ${data.dateOfReport}`,
      }
    },
    scales: {
      x: {
        bounds: 'data' as const,
        // // suggestedMin: suggestedMin,
        min: suggestedMin,
        // // suggestedMax: suggestedMax,
        max: suggestedMax,
        type: 'time' as const,
        ticks: {
          source: 'auto' as const,
          stepSize: 30,
          /** @note without a callback, the chart collapses every now and then */
          callback: (value: any) => {
            const dt = new Date(value)
            return ('0' + dt.getHours()).slice(-2)
              + ':'
              + ('0' + dt.getMinutes()).slice(-2)
          }
        },
        display: true,
        stacked: true
      }
    }
  }

  return <div><Bar className='daily-chart' data={cdata} options={options} /></div>
}

function DailyReportTable({ data, loading, error, ...props }: DailyReportChartJsProps) {
  if (loading) {
    return <div>Lade Daten...</div>
  }
  if (error) {
    return <div>{`Fehler beim Laden der Daten - ${error}`}</div>
  }

  const rows = data.rows
  const headings = Object.keys(rows[0])
  // Get index of date and time columns
  const dateIndex = headings.indexOf(utcDateColumn)
  const timeIndex = headings.indexOf(utcTimeColumn)
  // Remove local date and time columns from headings
  headings.splice(headings.indexOf(localDateColumn), 1)
  headings.splice(headings.indexOf(localTimeColumn), 1)
  // Replace utc date and time columns with local ones in headings
  headings.splice(dateIndex, 1, localDateColumn)
  headings.splice(timeIndex, 1, localTimeColumn)

  // Remove Date, WagenNumemr
  headings.splice(headings.indexOf(localDateColumn), 1)
  headings.splice(headings.indexOf('WagenNummer'), 1)

  return <table className='daily-report'>
    <thead>
      <tr key='th-row'>{headings.map(h => <th key={h}>{splitPascalCase(h.replace(' (Local)', '')).replace('Handbefuellungen', 'Handbe- fuellungen')}</th>)}</tr>
    </thead>
    <tbody>
      {rows.map((row, index) => {
        return <tr key={index}>
          {headings.map(h => <td key={h}>{row[h]}</td>)}
        </tr>
      })}
    </tbody>
  </table>
}

const splitPascalCase = (text: string) => {
  return text.replace(/([A-Z])/g, " $1")
}

const toLocalStringDe = (datestr_utc: string) => {
  return new Date(datestr_utc).toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}

export default function DailyReport() {
  let params = useParams()

  const [maschineData, setMaschineData] = useState(null)
  const [reportData, setReportData] = useState<DailyReportResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [dateOfReport, setDateOfReport] = useState<number | null>(null)

  useEffect(() => {
    const fetchMaschineData = async (seriennummer: string) => {
      const apiName = 'maschinenApi'
      const path = `/maschine/${seriennummer}`
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }
      const maschine = await API.get(apiName, path, myInit)
      console.log('maschine', maschine)
      if (!maschine) {
        throw new Error(`Keine Maschinendaten für ${seriennummer} gefunden.`)
      }
      return maschine
    }
    const fetchReportData = async (seriennummer: string, dateOfReport: string) => {
      const apiName = 'maschinenApi'
      const path = `/maschine/${seriennummer}/reports/daily/${dateOfReport}`
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
      }
      const report = await API.get(apiName, path, myInit)
      if (!report || !report.rows) {
        throw new Error(`Keine Daten für ${seriennummer} und ${dateOfReport} gefunden.`)
      }
      return report
    }
    const fetchAllData = async (params: any) => {
      try {
        const [myMaschineData, myReportData] = await Promise.all([
          fetchMaschineData(params.seriennummer!),
          fetchReportData(params.seriennummer!, params.date!)
        ])
        console.log(myMaschineData)
        setMaschineData(myMaschineData)
        setReportData(myReportData)
      } catch (err) {
        console.error('Error:', err)
        setMaschineData(null)
        setReportData(null)
        if (err instanceof Error) {
          setError(err.message)
        } else {
          setError((err as any).toString())
        }
      } finally {
        setLoading(false)
      }
    }
    fetchAllData(params)
    setDateOfReport(new Date(params.date!).getTime())
  }, [params])

  if (!loading && !error) {
    if (reportData) {
      const rows = reportData.rows

      rows.forEach(row => {
        const dtUtc = row[utcDateColumn] + 'T' + row[utcTimeColumn] + 'Z'
        const dtLocalSplit = toLocalStringDe(dtUtc).split(',')
        row[localDateColumn] = dtLocalSplit[0].trim()
        row[localTimeColumn] = dtLocalSplit[1].trim()
      })
    }
  }
  // console.log('data', data)

  let content
  if (loading) {
    content = <>
      <br />
      Lade Daten...
    </>
  } else if (error) {
    content = <>
      <br />
      <div>{`Fehler beim Laden der Daten - ${error}`}</div>
    </>
  } else {
    content = <>
      <ReportHeader maschine={maschineData} />
      <br />
      <ErrorBoundary fallback={<div>Kann den Graph nicht darstellen.</div>}>
        <DailyReportChartJs data={reportData!} loading={loading} error={error} />
      </ErrorBoundary>
      <br />
      <DailyReportTable data={reportData!} loading={loading} error={error} />
    </>
  }

  if (dateOfReport) {
    const maschineDummy = {
      SerienNummer: params.seriennummer!
    }
    const oneDay = 24 * 3600 * 1000
    const dayBefore = new Date(dateOfReport - oneDay).toISOString().slice(0, 10)
    const dayAfter = new Date(dateOfReport + oneDay).toISOString().slice(0, 10)
    const dayLinks = <Flex style={{ width: "100%" }}
      direction={'row'} justifyContent="space-between">
      <a href={getLinkToMaschineDailyReport(maschineDummy, dayBefore)}>« vorheriger Tag</a>
      <a href={getLinkToMaschineDailyReport(maschineDummy, dayAfter)}>nächster Tag »</a>
    </Flex>
    content = <>
      {dayLinks}
      {content}
      {dayLinks}
    </>
  }

  return <div style={{ display: 'flex' }}>
    <main style={{ padding: '0rem 1rem' }}>
      <h2>Daily Report für {params.seriennummer} (&lt;- das ist die interne S/N) am {params.date}</h2>
      <Breadcrumbs seriennummer={params.seriennummer} level1={'reports'} level2={'daily-reports'} />
      {content}
    </main>
  </div>
}
