const url_dev = 'https://z39xw9hqpc.execute-api.eu-central-1.amazonaws.com'
const url_prod = 'https://1ncwbp3hhj.execute-api.eu-central-1.amazonaws.com'

export const isDev = process.env.NODE_ENV
	.toLowerCase()
	.startsWith('dev')
export const isProd = process.env.NODE_ENV
	.toLowerCase()
	.startsWith('prod')

const getApiUrl = () => {
	if (isDev) {
		console.log('Using dev API:', url_dev)
		return url_dev
	}
	return url_prod
}

const config = {
	apiGateway: {
		REGION: 'eu-central-1',
		URL: getApiUrl()
	}
}

export default config
