export default function StatusDetails({ maschine, ...props }) {
  const deviceState = maschine.deviceState
  if (deviceState && deviceState.anzahlBallen && deviceState.betriebsStunden) {
    return (
      <div>
        <strong>Kennzahlen</strong><br />
        Anzahl Ballen: {deviceState.anzahlBallen}<br />
        Betriebsstunden: {secondsToString(deviceState.betriebsStunden)}<br />
      </div>
    )
  }
  if (maschine.ext_sn && maschine.ext_sn.startsWith('411.')) {
    return (
      <div>
        <strong>Kennzahlen</strong><br />
        <div>Für ALB ready Maschinen können keine Kennzahlen angezeigt werden.</div>
      </div>
    )
  }
  return (
    <div>
      <strong>Kennzahlen</strong><br />
      <div>Für diese Maschine können keine Kennzahlen angezeigt werden.</div>
    </div>
  )
}

/** Converts a duration in seconds into a human-readable (German) string */
function secondsToString(seconds)
{
  const numyears = Math.floor(seconds / 31536000)
  const numdays = Math.floor((seconds % 31536000) / 86400) 
  const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600)
  const numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60)
  const numseconds = Math.floor((((seconds % 31536000) % 86400) % 3600) % 60)
  const yearsStr = numyears ? numyears + " Jahre" : ''
  const daysStr = numdays ? numdays + " Tage" : ''
  const hoursStr = numhours < 10 ? '0' + numhours : numhours
  const minsStr = numminutes < 10 ? '0' + numminutes : numminutes
  const secsStr = numseconds < 10 ? '0' + numseconds : numseconds
  const hmsStr = `${hoursStr}:${minsStr}:${secsStr} Stunden`
  return [yearsStr, daysStr, hmsStr].join(' ')
}
