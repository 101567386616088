import { Flex } from '@aws-amplify/ui-react';

import StandortDetails from './maschinen-details/StandortDetails';
import { Kommentare } from './maschinen-details/Kommentare';
import { SonstigeMaschinenDetails } from './maschinen-details/SonstigeMaschinenDetails';

interface ReportHeaderProps {
  maschine: any
}

export const ReportHeader = ({ maschine, ...props }: ReportHeaderProps) => {
  return (
    <Flex>
      <StandortDetails maschine={maschine} />
      <SonstigeMaschinenDetails maschine={maschine} />
      <Kommentare maschine={maschine} />
    </Flex>
  )
}