import * as React from 'react';
import { Outlet } from 'react-router-dom';
import './maschinen.css'

import { Amplify, API, Auth } from 'aws-amplify';
import config from '../config'
import MaschinenListe from '../components/maschinen-liste/maschinen-liste'
import SearchField from '../components/maschinen-liste/search-field'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'maschinenApi',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  }
});

const authPath = '/maschinen'

async function fetchData() {
  const apiName = 'maschinenApi'
  const path = authPath
  const myInit = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  };
  return await API.get(apiName, path, myInit)
  // return Promise.resolve(getMaschinen())
}

export default function Maschinen({ viewMode }) {
  const [maschinen, setMaschinen] = React.useState(undefined)
  const pushM1ToBack = 1
  const pushM2ToBack = -1

  React.useEffect(() => {
    fetchData()
      .then(res => {
        const items = Object.values(res)
        items.forEach(m => m.adresseKurz = m.adresse && m.adresse.ort && m.adresse.strasse
          ? `${m.adresse.ort}, ${m.adresse.strasse}`
          : undefined
        );
        // console.log('res: ', items)
        items.sort((m1, m2) => {
          // Sort decommissioned machines to end of list
          if (m1.machine_status !== m2.machine_status) {
            if (m1.machine_status === 'ausgebaut') {
              return pushM1ToBack
            }
            if (m2.machine_status === 'ausgebaut') {
              return pushM2ToBack
            }
            if (m1.machine_status === 'Dataplane deaktiviert') {
              return pushM1ToBack
            }
            if (m2.machine_status === 'Dataplane deaktiviert') {
              return pushM2ToBack
            }
          }

          // Sort by address
          if (m1.adresseKurz && !m2.adresseKurz) {
            return pushM2ToBack
          }
          if (!m1.adresseKurz && m2.adresseKurz) {
            return pushM1ToBack
          }
          if (m1.adresseKurz && m2.adresseKurz) {
            if (m1.adresseKurz < m2.adresseKurz) {
              return pushM2ToBack
            }
            if (m1.adresseKurz > m2.adresseKurz) {
              return pushM1ToBack
            }
          }

          // Sort by ext_sn
          return m1.ext_sn < m2.ext_sn
            ? pushM2ToBack
            : m1.ext_sn > m2.ext_sn
        })
        setMaschinen(items)
      })
      .catch(err => {
        setMaschinen([])
        console.error('Error fetching data from api')
        console.log(err)
      })
  }, [])

  const extraClassName = viewMode === "page"
    ? "psi-maschinenliste-page"
    : "psi-maschinenliste-sidebar"
  return <div style={{ display: 'flex' }}>
    <nav className={`psi-maschinenliste ${extraClassName}`}>
      <SearchField />
      <MaschinenListe maschinen={maschinen} />
    </nav>
    <main style={{ padding: '0rem 1rem' }}>
      <Outlet />
    </main>
  </div>
}
