import ConnectivityIndicator from '../ConnectivityIndicator'
import QueryNavLink from './QueryNavLink'
import { getLinkToMaschine } from '../../LinkUtils'

export default function MaschinenLink({ maschine, ...props }) {
  return <QueryNavLink
    style={({ isActive }) => {
      return {
        display: 'block',
        margin: '1rem 0',
        textDecoration: 'none',
        color: isActive ? 'white' : 'var(--su-blue)',
        backgroundColor: !isActive ? 'white' : 'var(--su-blue)',
        border: 'solid 1px',
        borderColor: !isActive ? 'white' : 'var(--su-blue)',
      };
    }}
    className="maschinen-link"
    to={getLinkToMaschine(maschine)}
  >
    <div style={{ paddingLeft: '2px', borderBottom: '1px solid var(--su-blue)' }}>
      {maschine.ext_sn}
      <div style={{ float: 'right', fontSize: '11px', color: 'black' }}>
        {maschine.presence
          ? <>
            <ConnectivityIndicator name="Sensor-Daten" isConnected={maschine.presence.isConnected} isDisabled={['ausgebaut', 'Dataplane deaktiviert'].includes(maschine.machine_status)} />
            {' '}
            <ConnectivityIndicator name="Fernwartung" isConnected={maschine.presence.isAjaConnected} isDisabled={['ausgebaut'].includes(maschine.machine_status)} />
          </>
          : 'Verbindung: n/a'
        }
      </div>
    </div>
    <div style={{
      backgroundColor: 'white',
      color: 'black',
      fontSize: '11px',
      paddingLeft: '2px',
      paddingBottom: '2px',
      textOverflow: 'ellipsis'
    }}>
      {maschine.adresseKurz
        ? <div className='psi-address-short'>{maschine.adresseKurz}</div>
        : <div>Adresse: n/a</div>}
      {maschine.machine_status !== 'aktiv' && maschine.machine_status !== 'n/a'
        ? <>{' '}  {maschine.machine_status.replace('Dataplane', 'Sensor-Daten')}</>
        : ''
      }
    </div>
  </QueryNavLink>
}
