export default function ConnectivityIndicator({ name, isConnected, isDisabled }) {
  if ("unknown" === isConnected) {
    isDisabled = true
  }
  return <span title={name} style={{
    display: 'inline-block',
    textAlign: 'center',
    lineHeight: '1.1em',
    height: '1.5em',
    width: '1.5em',
    borderRadius: '10px',
    border: '2px solid ' + (isDisabled ? 'DarkGray' : isConnected ? 'Teal' : 'DarkRed'),
    backgroundColor: (isDisabled ? 'LightGray' : isConnected ? 'YellowGreen' : 'LightCoral')
  }}>{name[0]}</span>
}
