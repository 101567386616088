export const roundHalfHourDown = (date: Date): Date => {
    const minutes = date.getMinutes();
    const hours = date.getUTCHours();
    const roundedMinutes = Math.floor(minutes / 30) * 30;
    const roundedHours = hours + Math.floor(minutes / 60);

    date.setUTCHours(roundedHours, roundedMinutes, 0, 0);
    return date;
}

export const roundHalfHourUp = (date: Date): Date => {
    const minutes = date.getMinutes();
    const hours = date.getUTCHours();
    const roundedMinutes = Math.ceil(minutes / 30) * 30;
    const roundedHours = hours + Math.floor(roundedMinutes / 60);

    date.setUTCHours(roundedHours, roundedMinutes % 60, 0, 0);
    return date;
}
