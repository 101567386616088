import { Outlet, Link } from 'react-router-dom';
import './App.css'

import AuthenticatorHeader from './components/AuthenticatorHeader'

import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import aws_exports from './aws-exports'
Amplify.configure(aws_exports)

const components = {
  Header: AuthenticatorHeader
}

export default function App() {
  return (
    <Authenticator components={components}>
      {({ signOut, user }) => (
        <div className="_App">
          <header className="App-header">
            <h1><img src="/logo.png" alt="logo" /> PSI</h1>
            <nav>
              <Link to="/">Start</Link> |{' '}
              <Link to="/maschinen/">Maschinen</Link> |{' '}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSePcxn7H145l-L3v3yMyv_nutLcZu-es03yrB8jsnRWkEmhEw/viewform?usp=sf_link" target='_blank'>Modem bestellen</a>
            </nav>
            <div className='user-account'>
              {user!.attributes!.email}{' '}
              <button onClick={signOut}>Sign out</button>
            </div>
          </header>
          <main>
            <Outlet />
          </main>
        </div>
      )}
    </Authenticator>
  );
}
